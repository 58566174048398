'use client';

import { TypographyV3 as Typography } from '@jouzen/ecom-components';

import { EmailLayout } from '@/app/components/layout';

import { EssenceHelpLink } from '../components';

const Content = (): JSX.Element => {
  return (
    <EmailLayout
      title="This Page is Temporarily Unavailable"
      description="This page is currently under maintenance. Please check back soon."
      help={
        <Typography className="flex flex-wrap gap-x-1.5">
          <span>Need help?</span>
          <span>
            Email <EssenceHelpLink />
          </span>
        </Typography>
      }
      imageSrc="/eop/woman-long-hair.jpg"
      partner="essence"
      isInvalid={false}
      isMaintenance={true}
    />
  );
};

export default Content;
