import { EssencePageLayout } from '../components';
import Content from './content';

/* Order Portal Maintenance Page */

const PageMaintenance = () => {
  return (
    <EssencePageLayout pageName="maintenance">
      <Content />
    </EssencePageLayout>
  );
};

export default PageMaintenance;
