'use client';

import {
  BodyLinkV3 as BodyLink,
  type BodyLinkV3Props as BodyLinkProps,
} from '@jouzen/ecom-components';

const EssenceHelpLink = ({
  children,
  ...props
}: BodyLinkProps): JSX.Element => {
  return (
    <BodyLink href="mailto:partnersupport@ouraring.com" {...props}>
      {children ?? 'partnersupport@ouraring.com'}
    </BodyLink>
  );
};

export default EssenceHelpLink;
